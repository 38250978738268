import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AuthInterceptor, CancelRequestInterceptor, WindowWrapper } from '@depot/@common';
import { DepotCommonModule } from '@depot/@common/depot-common.module';
import { DepotComponentsModule } from '@depot/@components/depot-components.module';
import { CustomErrorHandler } from '@depot/@extension';
import {
  AppLayoutComponent,
  AppOfflineComponent,
  LoginComponent,
  NewVersionDialogComponent,
  NewVersionSnackbarComponent,
  NotFoundComponent,
  NotificationDrawerComponent,
  ResetPasswordComponent,
  UserSettingComponent,
} from '@depot/@main';
import { AdminModule } from '@depot/admin/admin.module';
import { AppRoutingModule } from '@depot/app-routing.module';
import { PartsModule } from '@depot/parts/parts.module';
import { PriceBookLineModule } from '@depot/price-book/line/price-book-line.module';
import { PriceBookPageModule } from '@depot/price-book/page/price-book-page.module';
import { PutawayModule } from '@depot/putaway/putaway.module';
import { environment } from '@env';

@NgModule({
  declarations: [
    AppLayoutComponent,
    LoginComponent,
    NotFoundComponent,
    AppOfflineComponent,
    UserSettingComponent,
    // ConfirmationDialogComponent,
    // PromptDialogComponent,
    // MessageDialogComponent,
    // CameraDialogComponent,
    ResetPasswordComponent,
    NewVersionDialogComponent,
    NewVersionSnackbarComponent,
    NotificationDrawerComponent,
  ],
  exports: [
  ],
  bootstrap: [AppLayoutComponent],
  imports: [AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    DepotCommonModule,
    DepotComponentsModule,
    PartsModule,
    PutawayModule,
    AdminModule,
    PriceBookLineModule,
    PriceBookPageModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      // scope: environment.app_endpoint,
    })], providers: [
      { provide: ErrorHandler, useClass: CustomErrorHandler },
      { provide: WindowWrapper, useFactory: () => globalThis },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: CancelRequestInterceptor, multi: true },
      provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule {
  constructor() {
  }
}

// TODO test 123
// FIXME test 456
// HACK 567
// NOTE test
// review 12345
