import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import {
  AuthService,
  HelperService,
  RoleGuard,
  SettingsService,
  WindowWrapper
} from '@depot/@common';
import { GenericInfoRepositoryService, NotificationMessageFormat, NotificationRepositoryService, UserRepositoryService } from '@depot/@data';
import { IClientError, IEmployee, ILocalNotificationSettings } from '@depot/custom';

import { BehaviorSubject, catchError, finalize, of } from 'rxjs';

import { UTCDate } from '@date-fns/utc';

@Component({
  selector: 'depot-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss']
})
export class UserSettingComponent implements OnInit, OnDestroy {

  public SiteAdmin = RoleGuard.SiteAdmin;
  public user$ = new BehaviorSubject<IEmployee>(null);
  public timeZones$ = new BehaviorSubject<{ displayName: string; id: string }[]>([]);
  public form: UntypedFormGroup;
  public globalErrors$ = new BehaviorSubject<string[]>([]);
  public notificationSettings: ILocalNotificationSettings;
  constructor(
    private userRepo: UserRepositoryService,
    private genericInfoRepo: GenericInfoRepositoryService,
    private helper: HelperService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    public settingsService: SettingsService,
    private notificationRepo: NotificationRepositoryService,
    private window: WindowWrapper,
  ) {
    this.helper.IsGlobalBar$.set(true);
  }


  ngOnInit() {
    this.genericInfoRepo.getTimezones().subscribe(timezones => {
      this.timeZones$.next(timezones);
    });
    this.settingsService.notification().then(settings => {
      this.notificationSettings = settings;


      this.userRepo.getUser(this.authService.user.id).subscribe(user => {

        this.user$.next(user);
        this.form = this.fb.group({
          id: user.id,
          userName: user.userName,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          password: '',
          confirmPassword: '',
          timeZone: user.timeZone,
          location: user.location,
        });

        this.helper.IsGlobalBar$.set(false);
      });

    });
  }

  saveForm() {
    this.globalErrors$.next([]);
    this.helper.IsGlobalSpinner$.set(true);
    const input = this.form.getRawValue();
    this.userRepo.saveUser(input)
      .pipe(
        catchError((error: IClientError) => {
          this.helper.mapErrors(error, null, this.form, this.globalErrors$);
          this.helper.logger.error('Error saving user', error);
          return of();
        }),
        finalize(() => this.helper.IsGlobalSpinner$.set(false))
      )
      .subscribe(x => {
        this.helper.showMessage('Changes saved successful', 'success');
      });
  }

  public async notificationPermissionToggle(event: MatSlideToggleChange) {
    if (event.checked) {
      if (Notification.permission !== 'granted') {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          this.helper.showMessage('Permission Granted', 'success');
        } else {
          this.helper.showMessage('Permission Denied', 'warn');
          return;
        }
      } else {
        this.helper.showMessage('Notifications Turned off', 'success');

      }

    } else {
      this.helper.showMessage('Notifications Turned off', 'info');
    }
    this.notificationSettings.showNotification = event.checked;
    this.persistSettings();

  }

  public async persistSettings() {
    await this.settingsService.notification(this.notificationSettings);
  }

  public testNotification() {
    this.notificationRepo.addNotification({
      id: Math.random() * -1,
      messageFormat: NotificationMessageFormat.String,
      messageData: 'Test Message',
      createdDate: new UTCDate(),
      createdBy: 'Test Notification',
      groupId: '',
      userId: '',
      dismissedBy: null,
      dismissedDate: null
    });
  }

  ngOnDestroy(): void {
    this.helper.IsGlobalBar$.set(false);
  }




}
